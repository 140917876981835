.dece-card-container {
    display: flex;
    flex-direction: column;
    background: #00000087;
}

.dece-card-title {
    /* text-align: left; */
    /* padding-left: 38px; */
    margin-top: 1.5em;
    color: #FECF58;
    margin-bottom: 0.6em;

    animation: title-to-visible;
    animation-duration: 1.4s;
    animation-fill-mode: forwards;
}

.title-collapsed {
    color: #ffffff00;
}

@keyframes title-to-visible {
    from { color: #ffffff00;}
    to { visibility: #ffffff99;}
}

@keyframes title-to-invisible {
    from { visibility: visible;}
    to { visibility: hidden;}
}

.dece-card-text {
    display: grid;
    align-content: center;
    margin: 10%;
    margin-bottom: 1.5em;
}

.no-right-border {
    border-right: 0px;
}

@media(max-width: 500px) {
}