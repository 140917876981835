#top-scroll-over {
    background: url('../images/epic.jpg') no-repeat top fixed;
    background-size: cover;
    margin: 0;
    height: 400vh;
}

.cover-image-overlay {
    position: absolute;
    height: 300vh;
    width: 100%;
    top: 0;
    z-index: 0;
}

@media (max-width: 1000px) {
    .cover-image-overlay {
        height: 400vh;
    }
}