.dece-row {
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 10em;
    row-gap: 10px;
}

.dece {
    width: 20%;
    height: 33vh;
    background: #00000069;
    border: #ffffff57 0.2px solid;
    display: grid;
    align-content: center;
}

@media (max-width: 900px) {
    .dece-row {
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 5em;
    }
}