.vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2em;
}

.order-steps {
    width: fit-content;
    text-align: left;
}

.steps-container {
    display: grid;
    place-content: center;
    width: 85%;
}

.exemplu-button {
    border: 1px solid white;
    padding: 1.5em;
    display: grid;
    place-content: center;
    width: 24vw;
    background: #0000005e;
    cursor: pointer;
    height: 0;
    margin-top: 1em;
    margin-bottom: 2em;
}

.cc-item {
    margin-bottom: 20px;
}

.cc-details  {
    color: #dfdfdf;
}

a:link, a:visited {
    color: white;
}

.cc-expand-btn {
    cursor: pointer;
    color: #ffdf8e;
    font-weight: lighter;
}

.cc-hide {
    visibility: collapse;
    height: 0;
}

@media(min-width: 500px) and (max-width: 900px) {
    .exemplu-button {
        width: 24vw;
        margin-top: 0em;
        margin-bottom: 3em;
    }
}


@media(max-width: 500px) {

    .steps-container {
        width: 88%;
    }
    .exemplu-button {
        width: 35vw;
        margin-top: 0em;
        margin-bottom: 3em;
    }
}