.servicii-container {
    display: flex;
    flex-direction: column;
    row-gap: 1.7em;
}

@media(min-width: 500px) and (max-width: 900px) {

}


@media(max-width: 500px) {
    .servicii-container {
        margin: 15px;
    }
}