.cover-image-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    padding-bottom: 62px;
    margin-bottom: 20vh;
    max-width: 48%;
}

.cover-image-item {
    text-align: left;
    width: fit-content;
}

#cover-image-space  {
    height: 20vh;
}

@media(min-width: 500px) and (max-width: 900px) {
    .cover-image-container {
        max-width: 80%;
    }
}


@media(max-width: 500px) {
    .cover-image-container {
        margin: 15px;
        max-width: 85%;
    }

    .cover-image-item {
        margin: 15px;
    }
}