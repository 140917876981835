.pret-container {
    display: grid;
    grid-template-rows: 30% 1fr 1fr auto; 
    flex-basis: 25%;
    max-width: 30em;
    position: relative;
    padding: 20px;
}

.pret-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.pret-label {
    grid-row-start: 1;
    grid-row-end: 3;
    align-self: flex-end;
    color: #FECF58;
    text-align: left;
    margin-left: 15%;
    z-index: 1;
}

.pret-details {
    color: white;
}

.pret-value {
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: left;
    margin-left: 15%;
    z-index: 1;
}

.image-overlay {
    background: #00000075;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


@media(min-width: 500px) and (max-width: 900px) {
    .pret-container {
        padding: 5px;
    }
}


@media(max-width: 500px) {
    .pret-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;
        padding: 5px;
    }

    .pret-label {
        margin-left: 0;
        align-self: auto;
        text-align: center;
    }


    .pret-value {
        margin-left: 0;
        text-align: center;
    }

    .pret-details {
        min-height: 1.5rem;
    }
}