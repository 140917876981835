.serviciu-container {
    display: grid;
    grid-template-columns: 200px auto;
    height: 200px;
}

.serviciu-image {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    object-fit: cover;
}

.serviciu-title {
    width: 100%;
    background-color: #000000a3;
    text-align: center;
    z-index: 2;
    position: absolute;
    bottom: 21px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.serviciu-descriere {
    font-weight: lighter;
    text-align: left;
    display: grid;
    align-content: center;
    margin-left: 50px;
    height: 100%;
    overflow-y: auto;
}

.first-column {
    position: relative;
    height: inherit;
}

.s-uc-container {
    position: absolute;
    display: grid;
    place-content: center;
    width: 100%;
    z-index: 2;
    height: 100%;
    background: #000000a6;
}

.s-uc-content {
    transform: rotate(-45deg);
}

@media(min-width: 500px) and (max-width: 900px) {
    .serviciu-container {
        grid-template-columns: 11em auto;
        height: 11em;
    }

    .serviciu-descriere {
        margin-left: 30px;
        font-weight: lighter;
    }
}


@media(max-width: 500px) {
    .serviciu-container {
        grid-template-columns: 8em auto;
        height: 8em;
    }

    .serviciu-descriere {
        margin-left: 20px;
        font-weight: lighter;
    }

    .serviciu-title {
        bottom: 16px;
    }
}