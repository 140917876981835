.App {
  text-align: center;
  background: #293545;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Pentru fiecare elemnt din Home */
.with-margins {
  margin-left: 10vw;
  margin-right: 10vw;
}

.text-lg {
  font-size: 7em;
}

.text-lg-1 {
  font-size: 4rem;
  line-height: 4rem;
}


.text-lg-2 {
  font-size: 3rem;
}

.text-md-1 {
  font-size: 1.8rem;
}

.text-md-2 {
  font-size: 1.5rem;
}

.text-sm-1 {
  font-size: 1.5rem;
}

.text-sm-2 {
  font-size: 1.2rem;
}

.text-lighter {
  font-weight: lighter;
}


.section-title {
  margin-bottom: 1em;
  text-align: left;
}

.section-back {
    background: #293545;
}

.section-front {
    padding-top: 5em;
}

.space-sm {
  height: 5em;
}

.space-xs {
  height: 3em;
}

.space-xxs {
  height: 2em;
}

.space-xxs-mobile {
  height: 0;
}

.dark-blue-line {
  background: #6b7f9b;
  width: 100%;
  height: 1px;
}

.blue-line {
  background: #005ed3;
  width: 100%;
  height: 1px;
}

.layer-up {
  position: relative;
  /* z-index: 1; */
}

.show-on-mobile {
  visibility: hidden;
  height: 0;
  width: 0;
}

.disable-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

@media(min-width: 500px) and (max-width: 900px) {
  .with-margins {
    margin-left: 7vw;
    margin-right: 4vw;
  }

  .text-lg {
    font-size: 5em;
    line-height: 1.2em;
  }

  .text-lg-1 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .text-lg-2 {
    font-size: 2rem;
    line-height: 1.7rem;
  }

  .text-md-1 {
    font-size: 1.5rem;
  }

  .text-md-2 {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  .text-sm-1 {
    font-size: 1.2rem;
  }

  .text-sm-2 {
    font-size: 1rem;
  }

  .section-front {
      padding-top: 3em;
      padding-bottom: 3em;
  }

  .space-sm {
    height: 3em;
  }

  .space-xxs {
    height: 0.3em;
  }

  .space-xxs-mobile {
    height: 0.3em;
  }

  .show-on-mobile {
    visibility: visible;
    height: auto;
    width: auto;
  }
}

@media(max-width: 500px) {
  .with-margins {
    margin-left: 1px;
    margin-right: 1px;
  }

  .text-lg {
    font-size: 4.5em;
    line-height: 1em;
  }

  .text-lg-1 {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }

  .text-lg-2 {
    font-size: 2.1em;
    line-height: 1em;
  }

  .text-md-1 {
    font-size: 1.3em;
  }

  .text-md-2 {
    font-size: 1.1em;
    line-height: 1em;
  }

  .text-sm-1 {
    font-size: 1.1em;
    line-height: 1.1em;
  }

  .text-sm-2 {
    font-size: 1rem;
  }

  .section-title {
      margin: 15px;
      margin-left: 30px;
  }

  .section-front {
      padding-top: 2em;
      padding-bottom: 2em;
  }

  .space-sm {
    height: 2em;
  }

  .space-xs {
    height: 1em;
  }

  .show-on-mobile {
    visibility: visible;
    height: auto;
    width: auto;
  }

  .space-xxs {
    height: 0.5em;
  }

  .space-xxs-mobile {
    height: 0.5em;
  }

}