.et-container {
  color: #a2a2a2;
}

.text-not-collapsed {
  animation: text-go-white, text-go-up;
  animation-duration: 1.7s;
  animation-fill-mode: forwards;
}

@keyframes text-go-white {
  from {color: #a2a2a2;}
  to {color: white;}
}

@keyframes text-go-up {
  from{ translate: 0 40px};
  to{ translate: 0 0px};
}

.et-content {
  height: 6em;
}

.text-collapsed {
    color: #FECF58;
    font-weight: 600;
}