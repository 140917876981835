.tc-modal-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    display: grid;
    place-content: center;
}

.tc-modal-back-content {
    background-color: #d4dbe1;
    color: black;
    border-radius: 10px;
    width: 60vw;
}

.tc-modal-content {
    margin: 6em;
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    text-align: left;
}

.tc-modal-buttons {
    display: flex;
    justify-content: center;
    column-gap: 80px;
    margin-top: 1em;
}

.tc-modal-button {
    color: #bd8700;
    cursor: pointer;
}

@media(min-width: 500px) and (max-width: 900px) {
    .tc-modal-content {
        margin: 4em;
    }

    .tc-modal-back-content {
        width: 80vw;
    }
}

@media(max-width: 500px) {
    .tc-modal-content {
        margin: 2em;
    }

    .tc-modal-back-content {
        width: 90vw;
    }
}