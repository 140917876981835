.preturi-container {
    display: flex;
    height: 70vh;
    justify-content: center;
}

@media(min-width: 500px) and (max-width: 900px) {
    .preturi-container {
        height: 55vh;
    }
}


@media(max-width: 500px) {
    .preturi-container {
        height: 45vh;
    }
}