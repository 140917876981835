.tc-container {
    height: 65em;
    position: relative;
}

.tc-image {
    height: 65em;
    width: 100%;
    object-fit: cover;
    object-position: 20% 30%;
}

.tc-overlay {
    background: #0000005F;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
}

.tc-send-form {
    display: flex;
    flex-direction: column;
    padding: 7em;
    padding-top: 5em;
    row-gap: 15px;
}

.tc-nume {
    outline: none;
    background: #273143d6;
    color: white;
    border: none;
    padding: 20px;
    font-family: 'Grenze', serif;
    font-size: 1.2em;
    line-height: 1.2em; 
    font-weight: lighter;
}

.tc-around-card-list {
    position: relative;
    padding: 20px;
    background: #273143d6;
}

.tc-cards-list {
    width: 100%;
    height: 15rem;
    outline: none;
    resize: none;
    background: #27314300;
    color: white;
    border: none;
    font-family: 'Grenze', serif;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: lighter;
}

.tc-exemplu-c {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid #d6d6d6;
    padding: 0.5em;
    margin: 15px;
    margin-right: 37px;
    cursor: pointer;
    color: #d6d6d6;
}

.tc-address {
    outline: none;
    background: #273143d6;
    color: white;
    border: none;
    padding: 20px;
    font-family: 'Grenze', serif;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: lighter;
}

.tc-want-box {
    display: inline-flex;
    text-align: left;
    font-family: 'Grenze', serif;
    font-size: 1.5em;
    line-height: 1.1em;
    color: #dfdfdf;
}

.tc-want-box-checked {
    height: 30px;
}

.tc-plata-div {
    background: #273143d6;
    color: white;
    border: none;
    padding: 25px;
    padding-top: 25px;
    padding-bottom: 37px;
    display: flex;
    row-gap: 7px;
    flex-direction: column;
    align-items: baseline;
}

.plata-option {
    height: 27px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    font-weight: lighter;
}

.option-label {
    margin-left: 15px;
    text-align: left;
}

.option-icon {
    height: 95%;
}

.tc-button {
    align-self: center;
    margin: 2em;
    border: 1px solid white;
    padding: 1.5em;
    display: grid;
    place-content: center;
    width: 24vw;
    background: #0000005e;
    cursor: pointer;
    height: 0;
}

.middle-column {
    grid-column-start: 2;
}

.error {
    border: solid 1px #ffef6d;
}

.hide {
    visibility: hidden;
    width: 0;
}

.want-box-ck {
    margin-left: 15px;
    cursor: pointer;
}

@media(min-width: 500px) and (max-width: 900px) {
    .tc-container {
        height: 60em;
    }

    .tc-image {
        height: 60em;
        object-position: 30% 30%;
    }

    .tc-button {
        width: 24vw;
    }

    .tc-send-form {
        padding: 5em;
        padding-top: 6em;
    }

    .tc-want-box {
        font-size: 1.3em;
    }

    .tc-want-box-checked {
        height: 24px;
    }

    .plata-option {
        height: 25px;
    }

    .option-label {
        margin-left: 10px;
    }
}


@media(max-width: 500px) {
    .tc-container {
        height: 58em;
    }

    .tc-image {
        height: 58em;
        object-position: 26% 30%;
    }

    .tc-button {
        width: 35vw;
    }

    .tc-send-form {
        padding: 2.2em;
        padding-top: 4em;
    }

    .tc-want-box {
        font-size: 1.2em;
    }

    .tc-want-box-checked {
        height: 22px;
    }

    .plata-option {
        height: 22px;
    }

    .option-label {
        margin-left: 10px;
    }

    .tc-exemplu-c {
        padding: 0.4em;
        margin: 12px;
    }
}