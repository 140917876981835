#header-bar {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    justify-content: right;
    background: #00000042;
    padding: 10px;
    padding-right: 10px;
    padding-right: 50px;
}

@media(min-width: 500px) and (max-width: 900px) {
    #header-bar {
        column-gap:25px;
        padding-right: 30px;
    }
}


@media(max-width: 500px) {
    #header-bar {
        column-gap:10px;
        padding-right: 20px;
    }
}