.footer-container {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    justify-content: right;
    background: #293545;
    padding: 50px;
    padding-right: 10px;
    padding-right: 50px;
    padding-bottom: 0;
}

.logo {
    height: 5em;
    translate: 0px -20px;
}

.logo-wrapper {
    flex-grow: 1;
    text-align: left;
}

.ft-column {
    text-align: left;
}

.ft-item {
    color: #a0bad9;
}

.ft-value {
    color: white;
    margin-left: 10px;
}

@media(min-width: 500px) and (max-width: 900px) {
    .footer-container {
        column-gap: 20px;
        padding: 20px;
    }

    .logo {
        height: 3.5em;
        translate: 0px 0px;
    }

    .ft-column {
        font-size: 0.8em;
    }
}

@media(max-width: 500px) {
    .footer-container {
        column-gap: 15px;
        align-items: center;
        padding: 10px;
    }

    .logo {
        height: 3em;
        translate: 0px 0px;
    }

    .ft-column {
        font-size: 0.8em;
    }
}